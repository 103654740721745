function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
import Layer from '@mozaic-ds/freemarker/js/modules/_layer';
import { fetchStoresAroundRendering, initStoreContextSearch } from "./components/store-context-search/store-context-search";
import { initStoreContextCard } from "./components/store-context-card/store-context-card";
import { hitEventTms } from "integration-web-core--socle/js/assets/commons/_tms.js";
import { applyTrackingAndContextCookieOnStoreSearchBlock, applyTrackingAndContextCookieOnStoreSearchBlockWithoutCoordinates, integrateHtmlToStoreSearchBlock } from "./components/store-search-result/store-search-result";
import { retrieveCustomerContextCookieValue } from "../common/cookie-finder";
export const STORE_SEARCH = 'store-search';
export const ID_SELECTOR = 'data-selectorid';
export const STORE_CARD = 'store-card';
export const STORE_LAYER_TITLE = '.mc-layer__title';
export const CONTEXTUALIZATION_LAYER = 'openStoreContextLayer';
export const CONTEXTUALIZATION_LAYER_SEARCH_MODE_EVENT = 'openStoreContextLayerInSearchMode';
export let customerContextPostcode = '';
export let customerContextCity = '';
const KL_HIDDEN_CLASS = 'kl-hidden';
export class CustomerContextLayer extends lm.Composant {
  constructor(componentName) {
    super(componentName);
    _defineProperty(this, "isUserContextualizedWithStoreCookie", void 0);
    _defineProperty(this, "CustomerContextLayerBlock", void 0);
    _defineProperty(this, "storeSearchContainer", void 0);
    _defineProperty(this, "storeCardContainer", void 0);
    _defineProperty(this, "layer", void 0);
    this.CustomerContextLayerBlock = document.querySelector(`[${ID_SELECTOR}="component-customer-context-layer"]`);
    this.storeSearchContainer = this.CustomerContextLayerBlock.querySelector(`[${ID_SELECTOR}="${STORE_SEARCH}"]`);
    this.isUserContextualizedWithStoreCookie = this.CustomerContextLayerBlock.dataset.isusercontextualized === 'true';
    this.initLayer();
    window.addEventListener(CONTEXTUALIZATION_LAYER, () => this.layer.open());
    window.addEventListener(CONTEXTUALIZATION_LAYER_SEARCH_MODE_EVENT, e => this.loadSearchLayer(e));
    if (this.isUserContextualizedWithStoreCookie) {
      initStoreContextCard(this.CustomerContextLayerBlock);
    } else {
      this.storeSearchContainer.classList.remove(KL_HIDDEN_CLASS);
    }
    initStoreContextSearch();
  }
  onLayerOpening() {
    hitEventTms(window, 'cdl_event', JSON.stringify({
      'event_name': 'step.display',
      'event_data': {
        'feature_name': 'header',
        'step_name': this.isUserContextualizedWithStoreCookie ? 'information content' : 'store search',
        'funnel_name': 'contextualization layer'
      }
    }));
  }
  onLayerClosing(storeCardContainer, CustomerContextLayerTitle) {
    if (this.isUserContextualizedWithStoreCookie) {
      const layerTitleText = 'Mon magasin';
      setTimeout(() => {
        this.storeSearchContainer.classList.add(KL_HIDDEN_CLASS);
        storeCardContainer.classList.remove(KL_HIDDEN_CLASS);
        CustomerContextLayerTitle.innerHTML = layerTitleText;
      }, 250);
    }
  }
  initLayer() {
    this.storeCardContainer = this.CustomerContextLayerBlock.querySelector(`[${ID_SELECTOR}="${STORE_CARD}"]`);
    const CustomerContextLayerTitle = this.CustomerContextLayerBlock.querySelector(`${STORE_LAYER_TITLE}`);
    this.layer = new Layer({
      'classContent': 'js-store-context-layer',
      'onOpen': () => this.onLayerOpening(),
      'onClose': () => this.onLayerClosing(this.storeCardContainer, CustomerContextLayerTitle)
    });
  }
  loadSearchLayer(event) {
    this.openSearchLayer();
    const customerContextCookie = retrieveCustomerContextCookieValue();
    // TODO remove check of latitude and longitude when cookie will be removed if values not present
    if (customerContextCookie && customerContextCookie.latitude && customerContextCookie.longitude) {
      fetchStoresAroundRendering(customerContextCookie.latitude, customerContextCookie.longitude, event.detail).then(result => {
        customerContextPostcode = customerContextCookie.postcode;
        customerContextCity = customerContextCookie.city;
        integrateHtmlToStoreSearchBlock(result, this.storeSearchContainer);
        applyTrackingAndContextCookieOnStoreSearchBlock(this.storeSearchContainer, customerContextCookie.latitude, customerContextCookie.longitude);
      });
    } else {
      applyTrackingAndContextCookieOnStoreSearchBlockWithoutCoordinates(this.storeSearchContainer);
    }
  }
  openSearchLayer() {
    this.storeCardContainer && this.storeCardContainer.classList.add(KL_HIDDEN_CLASS);
    this.storeSearchContainer && this.storeSearchContainer.classList.remove(KL_HIDDEN_CLASS);
    this.layer.open();
  }
}
lm.DOMReady(() => {
  new CustomerContextLayer('CustomerContextLayer');
});